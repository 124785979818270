import service from '@/setup/request';
export default {
  /**
   * 获取进货单
   * @param {*} data
   * @returns
   */
  getBuyOrder(data) {
    return service({
      url: '/order/orderinfo/buyorders',
      method: 'get',
      params: data
    });
  },
  /**
   * 获取卖货单
   * @param {*} data
   * @returns
   */
  getSellOrder(data, signal) {
    return service({
      url: '/order/orderinfo/sellorders',
      method: 'get',
      params: data,
      signal
    });
  },
  /**
   * 创建订单
   * @param {*} data
   * @returns
   */
  creatdOrder(data) {
    return service({
      url: '/order/orderinfo/create',
      method: 'post',
      data
    });
  },
  /**
   * 创建订单（代下单）
   * @param {*} data
   * @returns
   */
  createGenerationOrder(data) {
    return service({
      url: '/order/orderinfo/createentrustorder',
      method: 'post',
      data
    });
  },
  /**
   * 获取订单详情
   * @param {*} id
   * @returns
   */
  getOrderDetail(id) {
    return service({
      url: '/order/orderinfo/detail',
      method: 'get',
      params: { id }
    });
  },
  /**
   * 关闭订单（商户）
   * @param {*} data
   * @returns
   */
  closeOrder(data) {
    return service({
      url: '/order/orderinfo/merchantclose',
      method: 'put',
      data
    });
  },
  /**
   * 关闭订单（代理商）
   * @param {*} data
   * @returns
   */
  supplierCloseOrder(data) {
    return service({
      url: '/order/orderinfo/supplierclose',
      method: 'put',
      data
    });
  },
  /**
   * 确认收货订单
   * @param {*} data
   * @returns
   */
  receiveOrder(data) {
    return service({
      url: '/order/orderinfo/receive',
      method: 'put',
      data
    });
  },
  /**
   * 订单添加收款
   * @param {*} data
   * @returns
   */
  addOrderMoney(data) {
    return service({
      url: '/order/ordercharge/add',
      method: 'post',
      data
    });
  },
  /**
   * 代理商接单
   * @param {*} data
   * @returns
   */
  supplierAcceptOrder(data) {
    return service({
      url: '/order/orderinfo/accept',
      method: 'put',
      data
    });
  },
  /**
   * 财务审核订单
   * @param {*} data
   * @returns
   */
  financialAuditOrder(data) {
    return service({
      url: '/order/orderinfo/financepass',
      method: 'put',
      data
    });
  },
  /**
   * 代理商发货确认
   * @returns
   */
  deliveryConfirm(data) {
    return service({
      url: '/order/orderinfo/storepass',
      method: 'put',
      data
    });
  },
  discardOrder(data) {
    return service({
      url: '/order/ordercharge/discard',
      method: 'put',
      data
    });
  }
};

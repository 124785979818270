import store from '.';
import { sumBy, sum } from 'lodash-es';

const getters = {
  // 商品分类
  category: state => state.goods.category,
  // 购物车商品数量
  shoppingCardCount: state => sum((state.goods.shopingCard || []).map(x => x.num || 0)),
  // 购物车商品价格
  shoppingCardTotalMoney: state => {
    const shoppingCardGoods = state.goods.shopingCard || [];
    const goods = state.goods.goodsList || [];
    const goodsWithNum = goods.map(x => {
      const cardGoods = shoppingCardGoods.find(z => z.id === x.id);
      const cardNum = (cardGoods ? cardGoods.num : 0) || 0;
      return { ...x, num: cardNum };
    });
    return sum(goodsWithNum.map(x => (x.currentPrice + x.packingDeposit) * x.num));
  },
  // 代理商代下单, 订单商品金额
  entrustOrderGoodsMoney: state =>
    sumBy(
      (state.supplierGoods.supplierGoods || []).filter(x => x.num > 0),
      x => x.num * x.currentPrice
    ),
  // 代理商代下单, 订单押金总额
  entrustOrderpackingDepositMoney: state =>
    sumBy(
      (state.supplierGoods.supplierGoods || []).filter(x => x.num > 0),
      x => x.num * x.packingDeposit
    ),
  // 代理商代下单, 订单总额
  entrustOrderTotalMoney: state =>
    sumBy(
      (state.supplierGoods.supplierGoods || []).filter(x => x.num > 0),
      x => x.num * (x.currentPrice + x.packingDeposit)
    ),
  // 用户权限
  permissions: state => state.app.userInfo.permissions || [],
  // 是否是普通商户 1-普通商户 2-代理商 10-总公司 0-其他
  merchantType: state => (state.app.userInfo.merchantInfo ? state.app.userInfo.merchantInfo.merchantType : 0)
};
export default getters;

import { get } from 'lodash-es';
import { ionAlert } from '.';
import { registerPlugin } from '@capacitor/core';

export default {
  /**
   * 注册插件
   */
  initPlugin() {
    try {
      registerPlugin('RBApp');
    } catch (e) {
      console.log('注册rbapp失败', e);
    }

    try {
      registerPlugin('RBScanCode');
    } catch (e) {
      console.log('注册RBScanCode失败', e);
    }

    try {
      registerPlugin('RBNotify');
    } catch (e) {
      console.log('注册RBNotify失败', e);
    }
  },
  /**
   * 跳转到dev页面
   */
  gotoDevMenu() {
    try {
      getRbapp().gotoDevMenu();
    } catch {
      ionAlert('当前版本app暂不支持该操作, 请升级app后重试!');
    }
  },
  /**
   * 获取accesstoken
   * @returns Promise
   */
  getAccessToken() {
    return getRbapp().getAccessToken();
  },
  /**
   * 退出登录
   */
  logout() {
    try {
      getRbapp().logout();
    } catch {
      ionAlert('当前版本app暂不支持该操作, 请升级app后重试!');
    }
  },
  /**
   * 扫码
   * @returns Promise 扫码结果
   */
  startScanCode() {
    try {
      return getRBScanCode().startScanCode();
    } catch {
      ionAlert('当前版本app暂不支持该操作, 请升级app后重试!');
    }
  },
  /**
   * reLaunch
   */
  reLaunch() {
    try {
      getRbapp().reLaunch();
    } catch {
      ionAlert('当前版本app暂不支持该操作, 请升级app后重试!');
    }
  }
};

const getRbapp = () => {
  return get(window, 'Capacitor.Plugins.RBApp');
};

const getRBScanCode = () => {
  return get(window, 'Capacitor.Plugins.RBScanCode');
};

export const getRBNotice = () => {
  return get(window, 'Capacitor.Plugins.RBNotify');
};

import { isArray, get } from 'lodash-es';
import store from '../store/index';

export default {
  mounted(el, binding, vnode, prevVNode) {
    const permissionValue = binding.value;
    if (!isArray(permissionValue)) {
      console.error(`权限值[${permissionValue}]不是字符串数组!`);
      return;
    }
    if (permissionValue.length === 0) {
      return;
    }
    const userPermissions = get(store.state.app.userInfo, 'permissions', []);
    let hasPermission = true;
    for (const p of permissionValue) {
      if (!userPermissions.some(z => z.trim().toLowerCase() === p.trim().toLowerCase())) {
        hasPermission = false;
        break;
      }
    }
    if (!hasPermission) {
      el.remove();
    }
  }
};

import service from '@/setup/request';
export default {
  /**
   * 地区树
   * @returns
   */
  areaTree() {
    return service({
      url: '/common/dic/areadata',
      method: 'get'
    });
  },
  /**
   * 首页数据
   * @returns
   */
  homeData() {
    return service({
      url: '/common/home/homedata',
      method: 'get'
    });
  },
  /**
   * 代理商待办数量
   * @returns
   */
  supplierTodoCount() {
    return service({
      url: '/common/home/supplierTodoCount',
      method: 'get'
    });
  },
  /**
   * 获取字典数据
   * @param {*} code
   * @returns
   */
  dicByCode(code) {
    return service({
      url: '/common/dic/query',
      method: 'get',
      params: { code }
    });
  },
  /**
   * 查询枚举数
   * @param {*} code
   * @returns
   */
  queryEnum(type) {
    return service({
      url: '/common/enum/' + type,
      method: 'get'
    });
  },
  /**
   * 文件上传
   * @param {*} data
   * @returns
   */
  fileUpload(data) {
    return service({
      url: '/common/file/upload',
      method: 'post',
      data
    });
  },
  imageCaptch() {
    return service({
      url: '/common/account/captcha',
      method: 'get'
    });
  },
  /**
   * 意见反馈
   * @param {*} data
   * @returns
   */
  feedbacks(data) {
    return service({
      url: '/common/feedback/submit',
      method: 'post',
      data
    });
  },
  getMyTodoCount() {
    return service({
      url: '/common/home/myTodoCount',
      method: 'get'
    });
  },
  getSystemMessageList(data) {
    return service({
      url: '/common/message/system/list',
      method: 'get',
      params: data
    });
  },
  getServiceMessageList(data) {
    return service({
      url: '/common/message/service/list',
      method: 'get',
      params: data
    });
  },
  getSystemMessageDetail(id) {
    return service({
      url: '/common/message/system/detail',
      method: 'get',
      params: { id }
    });
  },
  getServiceMessageDetail(id) {
    return service({
      url: '/common/message/service/detail',
      method: 'get',
      params: { id }
    });
  },
  serviceMessageRead(id) {
    return service({
      url: '/common/message/service/read',
      method: 'put',
      params: { id }
    });
  },
  systemMessageRead(id) {
    return service({
      url: '/common/message/system/read',
      method: 'put',
      params: { id }
    });
  },
  systemMessageReadAll() {
    return service({
      url: '/common/message/system/readall',
      method: 'put'
    });
  },
  serviceMessageReadAll() {
    return service({
      url: '/common/message/service/readall',
      method: 'put'
    });
  },
  getAppConfig() {
    return service({
      url: '/common/app/config',
      method: 'get'
    });
  }
};

import { BASE_API_URL, RSA_PUBLIC_KEY } from '@/config/env';
import store from '@/store';
import dayjs from 'dayjs';
import { IN_APP } from '@/config/env';
import rbApp from '@/utils/rbApp';
import { alertController, loadingController } from '@ionic/vue';
import JSEncrypt from 'jsencrypt';

/**
 * RSA加密
 * @param value 要加密的内容
 */
export const rsaEncrypt = value => {
  const encrypt = new JSEncrypt();
  encrypt.setPrivateKey(RSA_PUBLIC_KEY);
  return encrypt.encrypt(value);
};

/**
 * 获取用户token
 * @returns token
 */
export const getToken = async () => {
  if (IN_APP === '1') {
    const { data } = await rbApp.getAccessToken();
    const token = data.accessToken;
    return token;
  } else {
    return window.localStorage.getItem('TOKEN') || '';
  }
};
/**
 * 设置用户token
 * @param {string} token
 */
export const setToken = token => window.localStorage.setItem('TOKEN', token);

/**
 * 删除用户token
 * @param {string} token
 */
export const removeToken = () => window.localStorage.removeItem('TOKEN');

/**
 * sleep
 * @param ms 延迟毫秒数
 * @returns
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * 格式化时间
 * @param value 时间
 * @param format 格式
 * @returns
 */
export const formatDate = (value = '', format = 'YYYY-MM-DD HH:mm:ss') => (value ? dayjs(value).format(format) : '-');

/**
 * 随机生成密码
 * @param len 长度
 * @returns
 */
export const randomPassword = len => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-$%&@+!';
  let password = '';
  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * chars.length);
    password += chars[index];
  }
  return password;
};

/**
 * 替换文本编辑器内容
 * @param content 内容
 */
export const replaceEditorContent = content => {
  // 替换acceetoken为占位符
  content = content.replace(/access_token=([\d\D]*?)&/gi, (match, $1, offset, raw) => {
    return 'access_token=____USER_ACCESS_TOKEN____&';
  });

  // 替换baseurl
  content = content.replaceAll(BASE_API_URL, '____BASE_API_URL____');

  return content;
};

// 根据key对数组进行分组
export const ArrGroupByField = (originalArr, field) => {
  let tempArr = [];
  let endData = [];
  for (let i = 0; i < originalArr.length; i++) {
    if (tempArr.indexOf(originalArr[i][field]) === -1) {
      endData.push({
        [field]: originalArr[i][field],
        merchantName: originalArr[i].merchantName,
        data: [originalArr[i]]
      });
      tempArr.push(originalArr[i][field]);
    } else {
      for (let j = 0; j < endData.length; j++) {
        if (endData[j][field] == originalArr[i][field]) {
          endData[j].data.push(originalArr[i]);
          break;
        }
      }
    }
  }
  return endData;
};
export const updeteShopingCard = data => {
  let c = data
    .filter(f => {
      return f.num > 0;
    })
    .map(e => {
      return { id: e.id, num: e.num };
    });
  store.dispatch('setShopingCard', c);
};
// 同步购物车数据到商品列表
export const syncShopingCard = data => {
  const {
    state: {
      goods: { shopingCard }
    }
  } = store;
  return data.map(c => {
    if (shopingCard.length < 1) {
      c.num = 0;
    } else {
      for (const key in shopingCard) {
        if (c.id == shopingCard[key].id) {
          c.num = shopingCard[key].num;
          break;
        } else {
          c.num = 0;
        }
      }
    }
    return c;
  });
};
export const formatter = time => {
  let nowdata = time == null ? new Date() : new Date(time);
  let year = nowdata.getFullYear();
  let month = nowdata.getMonth() + 1;
  let date = nowdata.getDate();
  let nowtime = year + '-';
  if (month < 10) {
    nowtime += '0';
  }
  nowtime += month + '-';
  if (date < 10) {
    nowtime += '0';
  }
  nowtime += date;
  return nowtime;
};
export const formatPrice = data => {
  return (data / 100).toFixed(2);
};
// 获取图片地址
export const formatPic = async data => {
  const token = await getToken();
  let path = BASE_API_URL + 'common/file/preview?id=' + data + '&access_token=' + token;
  return path;
};
/**
 * 还原文本编辑器内容
 * @param content 内容
 */
export const restoreEditorContent = content => {
  content = content.replaceAll('____BASE_API_URL____', BASE_API_URL);
  content = content.replaceAll('____USER_ACCESS_TOKEN____', getToken());
  return content;
};

// 是否是生产版本, 开发版本
export const isProduction = () => process.env.NODE_ENV === 'production';
export const isDevelopment = () => process.env.NODE_ENV === 'development';

// 拨打电话
export const onCallPhone = async tel => {
  const alert = await alertController.create({
    header: '提示',
    mode: 'md',
    message: '确定要拨打[' + tel + ']吗',
    cssClass: 'ion-custom-confirm',
    buttons: [
      {
        text: '取消'
      },
      {
        text: '确定',
        handler: async () => {
          window.open('tel:' + tel);
        }
      }
    ]
  });
  await alert.present();
};

// 显示confirm
export const ionConfirm = async (message, callback, header) => {
  const alert = await alertController.create({
    header: header || '提示',
    mode: 'md',
    message: message,
    cssClass: 'ion-custom-confirm',
    buttons: [
      {
        text: '取消'
      },
      {
        text: '确定',
        handler: async () => {
          callback && callback();
        }
      }
    ]
  });
  await alert.present();
};

// 显示alert
export const ionAlert = async (message, callback) => {
  const alert = await alertController.create({
    header: '提示',
    mode: 'md',
    message: message,
    cssClass: 'ion-custom-confirm',
    buttons: [
      {
        text: '确定',
        handler: async () => {
          callback && callback();
        }
      }
    ]
  });
  await alert.present();
};

import api from './api.js';
import address from './address.js';
import goods from './goods.js';
import merchant from './merchant.js';
import order from './order.js';
import account from './account.js';
import user from './user.js';
import agency from './agency.js';
import statistics from './statistics';
export default {
  api,
  address,
  goods,
  merchant,
  order,
  account,
  user,
  agency,
  statistics
};

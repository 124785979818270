/**
 * 打印日志
 * @param message 内容
 */
const log = message => {
  console.log(message);
};

/**
 * 获取env的值
 * @param name env name
 * @param defaultValue default value
 */
export function env(name, defaultValue) {
  const key = `VUE_APP_${name.toUpperCase()}`;
  const value = process.env[key] || defaultValue;
  log(`BUILDTIME ${key}=${value}`);
  return value;
}

/**
 * 获取runtime env的值
 * @param name env name
 * @param defaultValue default value
 */
export function runtimeEnv(name, defaultValue) {
  const key = `VUE_APP_${name.toUpperCase()}`;
  const runtimeValue = window && window.GLOBAL_VARIABLES && window.GLOBAL_VARIABLES[key];
  const buildtimeValue = env(name, defaultValue);
  const value = runtimeValue || buildtimeValue;
  log(`RUNTIME ${key}=${value}`);
  return value;
}

// 应用版本
export const VERSION = env('VERSION', '0');
// 服务端api地址
export const BASE_API_URL = runtimeEnv('BASE_API_URL', '');
// 应用名称
export const NAME = env('NAME', '');
// 是否在app内
export const IN_APP = runtimeEnv('IN_APP', '0');
// 是否在app内
export const RSA_PUBLIC_KEY = runtimeEnv('RSA_PUBLIC_KEY', '');

import service from '@/setup/request';
export default {
  /**
   * 获取我的所有通过审核的商户
   * @param {*} params
   * @returns
   */
  getMyAuditSuccessMerchants(params) {
    return service({
      url: '/common/merchant/mymerchants',
      method: 'get',
      params
    });
  },
  getMerchantInfo() {
    return service({
      url: '/merchant/merchantinfochange/getmerchantinfo',
      method: 'get'
    });
  },
  edidMerchantInfo(data) {
    return service({
      url: '/merchant/merchantinfochange/updatemerchantinfo',
      method: 'put',
      data
    });
  },
  getMerchantinfoAudit(id) {
    return service({
      url: '/merchant/merchantinfochangeaudit/detail',
      method: 'get',
      params: { id }
    });
  },
  /**
   * 根据id获取商户信息
   * @param {*} id
   * @returns
   */
  getMerchantByID(id) {
    return service({
      url: '/common/merchant/detail',
      method: 'get',
      params: { id }
    });
  },
  getMerchantList(data) {
    return service({
      url: '/merchant/merchant/list',
      method: 'get',
      params: data
    });
  },
  getMerchantDetail(id) {
    return service({
      url: '/merchant/merchant/detail',
      method: 'get',
      params: { id }
    });
  },
  /**
   * 获取员工列表
   * @returns
   */
  getMerchantUser() {
    return service({
      url: '/merchant/merchantuser/list',
      method: 'get'
    });
  },
  /**
   * 获取允许添加商户的角色
   * @returns
   */
  getMerchantUserRoles() {
    return service({
      url: '/merchant/merchantuser/getallowmerchantuserroles',
      method: 'get'
    });
  },
  /**
   * 修改商户信息
   * @param {*} data
   * @returns
   */
  editMerchantUserRoles(data) {
    return service({
      url: '/merchant/merchantuser/edit',
      method: 'put',
      data
    });
  },
  /**
   * 创建商户员工
   * @param {*} data
   * @returns
   */
  createMerchantUser(data) {
    return service({
      url: '/merchant/merchantuser/addbyqrcode',
      method: 'post',
      data
    });
  },
  /**
   * 珊瑚商户员工
   * @param {*} userId
   * @returns
   */
  delMerchantUser(userId) {
    return service({
      url: '/merchant/merchantuser/delete',
      method: 'delete',
      params: { userId }
    });
  },
  /**
   * 获取代理商绑定列表
   * @returns
   */
  getSupplierList() {
    return service({
      url: '/merchant/supplier/listnopage',
      method: 'get'
    });
  },
  // 与供应商解除关系
  delSupplier(id) {
    return service({
      url: '/merchant/supplier/delete',
      method: 'delete',
      params: { supplierId: id }
    });
  },
  /**
   * 获取全部代理商列表
   * @returns
   */
  getAllSupplier() {
    return service({
      url: '/merchant/merchantapply/suppliers',
      method: 'get'
    });
  },
  /**
   * 根据获取代理商
   * @returns
   */
  getSupplierById(params) {
    return service({
      url: '/merchant/merchantapply/supplierDetail',
      method: 'get',
      params
    });
  },
  /**
   * 添加代理商
   * @returns
   */
  addSupplier(data) {
    return service({
      url: '/merchant/supplier/add',
      method: 'post',
      data
    });
  },
  merchantApply(data) {
    return service({
      url: '/merchant/merchantapply/merchant',
      method: 'post',
      data
    });
  },
  supplierApply(data) {
    return service({
      url: '/merchant/merchantapply/supplier',
      method: 'post',
      data
    });
  },
  aiditMerchant(data) {
    return service({
      url: '/merchant/merchant/audit',
      method: 'put',
      data
    });
  },
  revokeAuditMerchant(merchantId) {
    return service({
      url: '/merchant/merchant/revokeAudit?merchantId=' + merchantId,
      method: 'put'
    });
  },
  deleteMerchant(data) {
    return service({
      url: '/merchant/merchant/delete',
      method: 'delete',
      params: data
    });
  },

  getMerchantBaseInfo(id) {
    return service({
      url: '/merchant/merchantstatistics/detail/baseinfo',
      method: 'get',
      params: { id }
    });
  },
  getMerchantstatisticsOrder(params) {
    return service({
      url: '/merchant/merchantstatistics/detail/orders',
      method: 'get',
      params
    });
  },
  getThirtydayorderstatistics(id) {
    return service({
      url: '/merchant/merchantstatistics/detail/thirtydayorderstatistics',
      method: 'get',
      params: { id }
    });
  },
  getTwelvemonthorderstatistics(id) {
    return service({
      url: '/merchant/merchantstatistics/detail/twelvemonthorderstatistics',
      method: 'get',
      params: { id }
    });
  }
};

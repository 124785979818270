export default {
  showNavBar: true,
  isDefault: true,
  conditionalTypes: {
    Equal: 1,
    In: 10,
    GreaterEqual: 5,
    LessEqual: 7
  },
  dialogTitle: '提示',
  dialogText: {
    deleteMerchantUser: '确定要删除该员工？',
    removeShopingCardGoods: '确定要移除该商品?',
    clearCache: '确定清除缓存？',
    confirmReceipt: '确认收到商品？',
    delAddress: '确认删除地址？',
    setDefaultAddress: '确定设置默认地址'
  },
  toastText: {
    addShopingCardSuccess: '加入购物车成功',
    emptyShopingCard: '请先选择要下单的商品',
    clearCacheLoadingText: '正在清理',
    clearCacheSuccess: '清理成功',
    closeOrderCause: '请选择关闭订单原因',
    takeNameEmpty: '请输入收货人姓名',
    takeTelEmpty: '请输入收货人电话',
    takeTelInvalid: '收货人电话格式有误',
    takeAddressEmpty: '请选择收货地址',
    takeAddressIndoEmpty: '请输入详细的收货地址',
    rolesEmpty: '请选择角色',
    submitOrderLoadingText: '正在提交订单',
    emptyFeedbackType: '请选择反馈类型',
    emptyFeedbackCont: '请输入反馈内容',
    emptyFeedbackImage: '请上传反馈图片'
  }
};

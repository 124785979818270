import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "loadingWarp"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_loading = _resolveComponent("van-loading");

  var _component_ion_router_outlet = _resolveComponent("ion-router-outlet");

  var _component_ion_app = _resolveComponent("ion-app");

  return _openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(function () {
      return [!$data.userInfoLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_loading, {
        type: "spinner"
      })])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_ion_router_outlet, {
        mode: "ios"
      })]))];
    }),
    _: 1
  });
}
import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import '@/assets/style/app.less';
import '@/assets/style/vant-magic.less';
import '@/assets/style/ionic-magic.less';
import '@ionic/vue/css/core.css';
import 'normalize.css';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/image-preview/style';
import 'font-awesome/css/font-awesome.min.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import App from './App.vue';
import router from './router';
import store from './store';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import mitt from 'mitt';
import './setup/userinfo';
import VueClipBoard from 'vue-clipboard2';
import 'echarts';
import ECharts from 'vue-echarts';
import { IonPage, IonContent, IonHeader, IonFooter, IonRefresher, IonRefresherContent } from '@ionic/vue';
import 'dayjs/locale/zh-cn';
import { setToken } from '@/utils';
import rbApp from '@/utils/rbApp';
import { Dialog, Toast, Lazyload } from 'vant';
import VueVirtualScroller from 'vue-virtual-scroller';
import { ConfigProvider } from 'vant';
dayjs.extend(relativeTime);
import config from '@/config';
import api from './api/index';
import { permission } from './directives';
import { IN_APP } from '@/config/env';

// 不是在app内, 使用假的token
if (IN_APP !== '1') {
  // 代理商
  setToken('eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM0ODAyOTEzNjY2NjcyNjQiLCJuYW1lIjoi6aG55a6P5LyfIiwiZXhwIjoxNjkxMDQwMDIyfQ.MS4cyDmlbR8mh0vHKgfFyT083c0gLPxSpLZa6O-59Us');
  // 商户
  // setToken('eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM0ODA0ODQ3NDkyNDY0NjQiLCJuYW1lIjoi5Y2O6I6x5aOrIiwiZXhwIjoxNjkxMjE5OTQ5fQ.Jyi6qi8zWLlh43jJ_9HDJZY8uTTAIOw468bDL7kYVV8');
  // 商户员工
  // setToken('eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM0OTM4MTQxOTA3MzUzNjAiLCJuYW1lIjoi5aSp5LiAIiwiZXhwIjoxNjY2MzQwNzIxfQ.IWgX811ETaoQNnepA-AXlodUwzrPGYFkwCvM_MQgabo');
  // 正式环境代理商
  // setToken('eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM0OTU4NDUwMjg2OTE5NjkiLCJuYW1lIjoibGlsZWkiLCJleHAiOjE2OTg3MzExNDZ9.LJ7ENR-KvgDsUMYujQLlAEZWE-px18t9ROSqmfPQHyQ');
  // 正式环境管理员
  // setToken('eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM0ODM3MTI4OTc0MTcyMTYiLCJuYW1lIjoi5oC75YWs5Y-4566h55CG5ZGYIiwiZXhwIjoxNjk4NzMxMjI3fQ.CL0asyXkogp0QmfDtbeeW1AHA7H56egpey2He36Q8jc');
} else {
  rbApp.initPlugin();
}
dayjs.locale('zh-cn');

const app = createApp(App).use(IonicVue).use(store).use(router).use(Dialog).use(Toast).use(Lazyload);
app.use(ConfigProvider);

app.config.globalProperties.$api = api;
app.config.globalProperties.$config = config;
app.config.globalProperties.$eventBus = mitt();
app.config.productionTip = false;

app.use(VueVirtualScroller);

app.directive('permission', permission);
app.component('ion-page', IonPage);
app.component('ion-content', IonContent);
app.component('ion-header', IonHeader);
app.component('ion-footer', IonFooter);
app.component('ion-refresher', IonRefresher);
app.component('ion-refresher-content', IonRefresherContent);
app.component('v-chart', ECharts);

app.use(VueClipBoard);
router.isReady().then(() => {
  app.mount('#app');
});

import api from '@/api'
export default {
    state: {
        goodsWarningSetting:{}, // 商品预警配置
        merchantWarningSetting:{} // 客户预警配置
    },
    mutations: {
        setGoodsWarningSetting(state, data) {
            state.goodsWarningSetting = data;
        },
        setMerchantWarningSetting(state, data) {
            state.merchantWarningSetting = data;
        }
    },
    actions: {
        getGoodsWarningSetting({ commit }) {
            return new Promise(resolve => {
              api.agency.getGoodsWarningSetting().then(response => {
                   commit('setGoodsWarningSetting', response.data);
                   resolve(response.data);
              });
            });
        },
        getMerchantWarningSetting({ commit }) {
            return new Promise(resolve => {
              api.agency.getMerchantWarningSetting().then(response => {
                   commit('setMerchantWarningSetting', response.data);
                   resolve(response.data);
              });
            });
        }
    }
  }
import service from "@/setup/request";
export default {
    getWarningList(url,params){
        return service({
            url:url,
            method:'get',
            params
        })
    },
    /**
     * 商品预警数据
     * @returns 
     */
    getGoodsSummary(){
        return service({
            url:'/earlywarning/goods/summary',
            method:'get'
        })
    },
    /**
     * 客户预警数据
     * @returns 
     */
     getMerchantSummary(){
        return service({
            url:'/earlywarning/merchant/summary',
            method:'get'
        })
    },
    /**
     * 商品预警配置
     * @returns 
     */
    getGoodsWarningSetting(){
        return service({
            url:'/earlywarning/goodssetting/detail',
            method:'get'
        })
    },
    /**
     * 客户预警配置
     * @returns 
     */
     getMerchantWarningSetting(){
        return service({
            url:'/earlywarning/merchantsetting/detail',
            method:'get'
        })
    },
    /**
     * 更新商品预警设置
     * @param {*} data 
     * @returns 
     */
    updateGoodsWarningSetting(data){
        return service({
            url:'/earlywarning/goodssetting/update',
            method:'put',
            data
        })
    },
    /**
     * 更新客户预警设置
     * @param {*} data 
     * @returns 
     */
    updateMerchantWarningSetting(data){
        return service({
            url:'/earlywarning/merchantsetting/update',
            method:'put',
            data
        })
    },
    saletrend(data){
        return service({
            url:'/report/merchantordersreport/saletrend',
            method:'get',
            params:data
        })
    }
}


export default [
  // 关于
  {
    path: '/common/about',
    name: 'commonAbout',
    component: () => import('../views/common/About')
  },
  {
    path: '/common/feedback',
    name: 'commonFeedback',
    component: () => import('../views/common/Feedback')
  },
  // 员工列表
  {
    path: '/common/emplos/list',
    name: 'commonEmolosList',
    component: () => import('../views/common/emplos/List')
  },
  // 添加员工
  {
    path: '/common/emplos/create',
    name: 'commonEmolosCreate',
    component: () => import('../views/common/emplos/Create')
  },
  // 选择角色
  {
    path: '/common/role/select',
    name: 'commonRoleSelect',
    component: () => import('../views/common/platform/EnterSelect')
  },
  // 商户审核中
  {
    path: '/common/merchant/audit/status',
    name: 'commonMerchantAuditStatus',
    component: () => import('../views/common/platform/AuditStatus.vue')
  },
  // 员工二维码
  {
    path: '/common/my/qrcode',
    name: 'commonMyQrcode',
    component: () => import('../views/common/platform/QrCode')
  },
  // 商户注册
  {
    path: '/common/merchant/register',
    name: 'commonMerchantRegister',
    component: () => import('../views/common/platform/PerfectInfo')
  },
  // 用户信息
  {
    path: '/common/my/userinfo',
    name: 'commonMyUserinfo',
    component: () => import('../views/common/UserInfo')
  },
  // 商户信息
  {
    path: '/common/my/merchantinfo',
    name: 'commonMyMerchantinfo',
    component: () => import('../views/common/my/merchantInfo/Index.vue')
  },
  // 商户信息修改
  {
    path: '/common/my/editmerchantinfo',
    name: 'commonMyEditMerchantinfo',
    component: () => import('../views/common/my/merchantInfo/Edit.vue')
  },
  // 商户信息修改详情
  {
    path: '/common/my/showmerchantinfo/:id',
    name: 'commonMyShowMerchantinfo',
    component: () => import('../views/common/my/merchantInfo/Show.vue')
  },
  // 订单详情(采购订单)
  {
    path: '/common/buyOrder/detail',
    name: 'commonBuyOrderDetail',
    component: () => import('../views/common/buyOrder/OrderDetail')
  },
  // 订单详情(采购订单)
  {
    path: '/common/buyOrder/confirm',
    name: 'commonBuyOrderConfirm',
    component: () => import('../views/common/buyOrder/OrderConfirm.vue')
  },
  // 订单成功(采购订单)
  {
    path: '/common/buyOrder/success',
    name: 'commonBuyOrderSuccess',
    component: () => import('../views/common/buyOrder/OrderSuccess.vue')
  },
  // 订单列表
  {
    path: '/common/buyOrder/list',
    name: 'commonBuyOrderList',
    component: () => import('../views/common/buyOrder/OrderListPage.vue')
  },
  // 收藏列表
  {
    path: '/common/my/favorites',
    name: 'commonMyFavorites',
    component: () => import('../views/common/my/Favorites.vue')
  },
  // 修改密码
  {
    path: '/common/account/changePassword',
    name: 'commonAccountChangePassword',
    component: () => import('../views/common/account/ChangePassword.vue')
  },
  // 我的代理商
  {
    path: '/common/supplier/list',
    name: 'commonSupplierList',
    component: () => import('../views/common/supplier/List')
  },
  // 添加代理商
  {
    path: '/common/supplier/add',
    name: 'commonSupplierAdd',
    component: () => import('../views/common/supplier/Add')
  },
  // 选择代理商
  {
    path: '/common/select/supplier',
    name: 'commonSelectSupplier',
    component: () => import('../views/common/SelectSupplier')
  },
  // 消息详情
  {
    path: '/common/message/detail',
    name: 'commonMessageDetail',
    component: () => import('../views/common/message/Detail')
  },
  // 系统消息
  {
    path: '/common/message/system/list',
    name: 'commonMessageSystemList',
    component: () => import('../views/common/message/System')
  },
  // 服务消息
  {
    path: '/common/message/service/list',
    name: 'commonMessageServiceList',
    component: () => import('../views/common/message/Service')
  },
  // 修改手机号
  {
    path: '/common/account/changePhone',
    name: 'commonAccountChangePhone',
    component: () => import('../views/common/account/ChangePhone.vue')
  },
  // 账号与安全
  {
    path: '/common/account/index',
    name: 'commonAccountIndex',
    component: () => import('../views/common/account/Index.vue')
  },
  // 注销账号
  {
    path: '/common/account/cancellation',
    name: 'commonAccountCancellation',
    component: () => import('../views/common/account/CancellationAccont.vue')
  },
  // 注销账号完成
  {
    path: '/common/account/cancellationComplete',
    name: 'commonCccountComplete',
    component: () => import('../views/common/account/CancellationComplete.vue')
  },
  // 用户协议
  {
    path: '/common/about/userAgreement',
    name: 'commonAboutUserAgreement',
    component: () => import('../views/common/UserAgreement.vue')
  },
  // 隐私政策
  {
    path: '/common/about/privacyPolicy',
    name: 'commonAboutPrivacyPolicy',
    component: () => import('../views/common/PrivacyPolicy.vue')
  }
];

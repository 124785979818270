export default [
  // 代理商首页
  {
    path: '/agency/main',
    name: 'agencyMain',
    component: () => import('../views/agency/main/AgencyMain')
  },
  // 代理商订单详情
  {
    path: '/agency/order/sell/detail',
    name: 'agencyOrderSellDetail',
    component: () => import('../views/agency/order/AuditOrder')
  },
  // 代下单
  {
    path: '/agency/entrust/order/create',
    name: 'agencyEntrustOrderCreate',
    component: () => import('../views/agency/entrustOrder/CreateOrder.vue')
  },
  // 代下单选择商户
  {
    path: '/agency/entrust/order/selectMerchant',
    name: 'agencyEntrustOrderSelectMerchant',
    component: () => import('../views/agency/entrustOrder/SelectMerchant')
  },
  // 代下单选择商品
  {
    path: '/agency/entrust/order/selectGoods',
    name: 'agencyEntrustOrderSelectGoods',
    component: () => import('../views/agency/entrustOrder/SelectGoods')
  },
  // 代下单选择收货地址
  {
    path: '/agency/entrust/order/selectAddress/:id',
    name: 'merchantAddress',
    component: () => import('../views/agency/entrustOrder/SelectAddress')
  },
  // 代下单成功
  {
    path: '/agency/entrust/order/success',
    name: 'agencyEntrustOrderSuccess',
    component: () => import('../views/agency/entrustOrder/Success.vue')
  },
  // 添加代理商商品
  {
    path: '/agency/goods/add',
    name: 'agencyGoodsAdd',
    component: () => import('../views/agency/goods/AddGoods.vue')
  },
  // 添加代理上商品(从商品库选择)
  {
    path: '/agency/goods/changeGoods',
    name: 'agencyGoodsChangeGoods',
    component: () => import('../views/agency/goods/ChangeGoods')
  },
  // 修改代理商商品
  {
    path: '/agency/goods/edit/:id',
    name: 'agencyGoodsEdit',
    component: () => import('../views/agency/goods/EditGoods.vue')
  },
  // 创建采购订单
  {
    path: '/agency/buyOrder/create',
    name: 'agencyBuyOrderCreate',
    component: () => import('../views/agency/buyOrder/Create.vue')
  },
  // 代理商二维码
  {
    path: '/agency/my/merchant/qrcode',
    name: 'agencyMyMerchantQrcode',
    component: () => import('../views/agency/mine/SupplierQrcode.vue')
  },
  // 商户详情
  {
    path: '/agency/merchant/detail',
    name: 'agencyMerchantDetail',
    component: () => import('../views/agency/merchants/Detail.vue')
  },
  // 商户审核
  {
    path: '/agency/merchant/audit/list',
    name: 'agencyMerchantAuditList',
    component: () => import('../views/agency/merchants/audit/List.vue')
  },
  // 商户审核详情
  {
    path: '/agency/merchant/audit/detail',
    name: 'agencyMerchantAuditDetail',
    component: () => import('../views/agency/merchants/audit/Detail.vue')
  },
  // 代理商订单
  {
    path: '/agency/sellorder/list',
    name: 'agencySellOrder',
    component: () => import('../views/agency/order/SellOrder')
  },
  // 代理商关闭订单
  {
    path: '/agency/sellorder/close',
    name: 'agencySellOrderClose',
    component: () => import('../views/agency/order/CloseOrder.vue')
  },
  // 代理商添加收款记录
  {
    path: '/agency/sellorder/charge/add',
    name: 'agencySellOrderChargeAdd',
    component: () => import('../views/agency/order/ChargeAdd.vue')
  },
  // 商品详情
  {
    path: '/agency/goods/detail',
    name: 'agencyGoodsDetail',
    component: () => import('../views/agency/goods/GoodsDetail.vue')
  },
  // 商户预警概览
  {
    path: '/agency/earlywarning/merchant/summary',
    name: 'agencyEarlywarningMerchantSummary',
    component: () => import('../views/agency/home/MerchantWarning')
  },
  // 商户预警列表
  {
    path: '/agency/earlywarning/merchant/list',
    name: 'agencyEarlywarningMerchantList',
    component: () => import('../views/agency/home/MerchantWarningList')
  },
  // 商户预警设置
  {
    path: '/agency/earlywarning/merchant/setting',
    name: 'agencyEarlywarningMerchantSetting',
    component: () => import('../views/agency/home/MerchantWarningSetting')
  },
  // 商品预警设置
  {
    path: '/agency/earlywarning/goods/setting',
    name: 'agencyEarlywarningGoodsSetting',
    component: () => import('../views/agency/home/GoodsWarningSetting')
  },
  // 商品预警概览
  {
    path: '/agency/earlywarning/goods/summary',
    name: 'agencyEarlywarningGoodsSummary',
    component: () => import('../views/agency/home/GoodsWarning')
  },
  // 商品预警列表
  {
    path: '/agency/earlywarning/goods/list',
    name: 'agencyEarlywarningGoodsList',
    component: () => import('../views/agency/home/GoodsWarningList')
  }
];

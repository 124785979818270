import apis from '@/api/goods';
import { syncShopingCard } from '@/utils/index';
export default {
  state: {
    category: [], // 商品分类列表
    shopingCard: [], // 商户购物车
    shopingCardChecked: [], // 购物车选中商品id列表
    goodsList: [], // 商户所有代理商商品
    merchantGoodsList: [], // 商户商品列表
    cardCount: 0, // 购物车商品数量
    orderData: [], // 下单商品数据
    orderAddress: {},
    favoriteGoods: [], // 收藏的商品
    favoriteGoodsId: [] // 收藏的商品Id, 判断是否收藏
  },
  mutations: {
    setMerchantGoodsList(state, data) {
      state.merchantGoodsList = data;
    },
    setShopingCardChecked(state, value) {
      state.shopingCardChecked = value;
    },
    setFavoriteGoods(state, data) {
      state.favoriteGoods = data;
      state.favoriteGoodsId = (data || []).map(x => x.id);
    },
    favoriteGoods(state, id) {
      state.favoriteGoodsId = [...state.favoriteGoodsId, id];
    },
    unFavoriteGoods(state, id) {
      state.favoriteGoodsId = state.favoriteGoodsId.filter(x => x !== id);
    },
    setOrderAddress(state, data) {
      state.orderAddress = data;
    },
    setCategory(state, data) {
      state.category = data;
    },
    setOrderData(state, data) {
      state.orderData = data;
    },
    setShopingCard(state, data) {
      let count = 0;
      data.forEach(e => {
        count += e.num;
      });
      state.cardCount = count;
      state.shopingCard = data;
    },
    setGoodsList(state, data) {
      state.goodsList = data;
    },
    setSelectAllShoppingCard(state) {
      const ids = state.shopingCard.map(x => x.id);
      state.shopingCardChecked = ids;
    }
  },
  actions: {
    // 从本地储存获取商户购物车数据
    LoadMerchantShopingCardData({ commit }) {
      let shopingCard = [];
      try {
        shopingCard = window.localStorage.getItem('shopingCard') || '[]';
      } catch {}
      commit('setShopingCard', JSON.parse(shopingCard));
    },
    // 更新商户购物车
    setShopingCard({ commit, ...b }, data) {
      window.localStorage.setItem('shopingCard', JSON.stringify(data));
      commit('setShopingCard', data);
    },
    /**
     * 添加商品到购物车
     * @param {*} param0
     * @param {*} param1
     */
    addShoppingCartGoods({ dispatch, state }, { id, num }) {
      const goods = [...(state.shopingCard || [])];
      const current = goods.find(x => x.id === id);
      if (current) {
        current.num += 1;
      } else {
        goods.push({ id, num });
      }
      dispatch('setShopingCard', goods);
    },
    /**
     * 获取商品分类
     * @param {*} param0
     * @returns
     */
    getCategory({ commit }) {
      apis.getCategoryList().then(response => {
        let list = response.data.map(item => {
          item.text = item.name;
          return item;
        });
        commit('setCategory', [{ text: '全部', id: 0, cover: '' }, ...list]);
      });
    },
    /**
     * 获取商品库
     * @param {*} param0
     * @returns
     */
    getGoods({ commit, state, dispatch }) {
      return new Promise(resolve => {
        apis.getGoods().then(response => {
          const goods = response.data;
          commit('setGoodsList', goods);
          // 删除购物车中不存在的商品
          const shoppingCartGoods = state.shopingCard || [];
          const newShoppingCartGoods = shoppingCartGoods.filter(x => goods.some(g => g.id === x.id));
          dispatch('setShopingCard', newShoppingCartGoods);

          resolve(goods);
        });
      });
    },
    getMerchantGoodsList({ commit }) {
      return new Promise(resolve => {
        apis.getMerchantGoods().then(response => {
          commit('setMerchantGoodsList', response.data || []);
          resolve(response.data);
        });
      });
    },
    /**
     * 获取收藏商品
     * @param {*} param0
     * @returns
     */
    getFavoriteGoods({ commit }) {
      return new Promise(resolve => {
        apis.getFavoriteGoods().then(response => {
          commit('setFavoriteGoods', response.data);
          resolve(response.data);
        });
      });
    }
  }
};

import api from '@/api';
export default {
  state: {
    areaTree: [], // 省市区数据
    addressData: [], // 收货地址列表
    userInfo: {},
    myTodoCount: {
      unReadSystemMessageCount: 0,
      unReadServiceMessageCount: 0,
      unAcceptOrderCouhnt: 0,
      unSendOrderCouhnt: 0,
      unReceiveOrderCouhnt: 0
    },
    supplierTodoCount: {
      acceptOrderCount: 0,
      financeOrderCount: 0,
      merchantCount: 0,
      shipOrderCount: 0
    },
    // app配置信息
    appConfig: {}
  },
  mutations: {
    setAppConfig(state, data) {
      state.appConfig = data;
    },
    setArea(state, data) {
      state.areaTree = data;
    },
    setAddress(state, data) {
      state.addressData = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    serMyTodoCount(state, data) {
      state.myTodoCount = data;
    },
    serSupplierTodoCount(state, data) {
      state.supplierTodoCount = data;
    }
  },
  actions: {
    getAppConfig({ commit }) {
      return new Promise((resolve, reject) => {
        api.api.getAppConfig().then(response => {
          commit('setAppConfig', response.data);
          resolve(response.data);
        });
      });
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        api.user.getUserInfo().then(response => {
          commit('setUserInfo', response.data);
          resolve(response.data);
        });
      });
    },
    /**
     * 获取消息数量
     * @param {*} param0
     */
    getMyTodoCount({ commit }) {
      return new Promise((resolve, reject) => {
        api.api.getMyTodoCount().then(response => {
          commit('serMyTodoCount', response.data);
          resolve(response.data);
        });
      });
    },
    /**
     * 获取代理商待办数量
     * @param {*} param0
     */
    getSupplierTodoCount({ commit }) {
      return new Promise((resolve, reject) => {
        api.api.supplierTodoCount().then(response => {
          commit('serSupplierTodoCount', response.data);
          resolve(response.data);
        });
      });
    },
    /**
     * 获取省市区数据
     * @param {*} param0
     * @returns
     */
    getArea({ commit }) {
      return new Promise((resolve, reject) => {
        api.api.areaTree().then(response => {
          commit('setArea', response.data);
        });
      });
    },
    /**
     * 获取全部收获地址
     * @param {*} param0
     * @returns
     */
    getAddress({ commit }) {
      return new Promise((resolve, reject) => {
        api.address.shippingaddress().then(response => {
          const {
            data: { records }
          } = response;
          commit('setAddress', records);
          resolve(records);
        });
      });
    }
  }
};

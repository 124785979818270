import { createRouter, createWebHistory } from '@ionic/vue-router';
import commonRouters from './common';
import merchantRouters from './merchant';
import supplierRouters from './supplier';
import testRouters from './test';

const routerList = [
  // 首页
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index')
  },
  ...merchantRouters,
  ...supplierRouters,
  ...commonRouters,
  ...testRouters,
  {
    path: '/salesStatistics',
    name: 'salesStatistics',
    component: () => import('../views/agency/home/SalesStatistics')
  },
  {
    path: '/orderStatistics',
    name: 'orderStatistics',
    component: () => import('../views/agency/home/OrderStatistics')
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: () => import('../views/common/address/List')
  },
  {
    path: '/createAddress',
    name: 'createAddress',
    component: () => import('../views/common/address/Create')
  },
  {
    path: '/orderAddressList',
    name: 'orderAddressList',
    component: () => import('../views/common/address/OrderAddressList')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routerList
});

export default router;

import api from '@/api'
export default {
    state: {
        merchantGoodsList:[] // 经销商商品列表
    },
    mutations: {
        setMerchantGoodsList(state, data) {
            state.merchantGoodsList = data;
        }
    },
    actions: {
        getMerchantGoodsList({ commit }) {
            return new Promise(resolve => {
              api.goods.getMerchantGoods().then(response => {
                   let data = response.data.map(m => {
                       m.num = 0
                       return m
                   })
                   commit('setMerchantGoodsList', data);
                   resolve(data);
              });
            });
        }
    }
  }
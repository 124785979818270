import service from '@/setup/request';
export default {
  getDetailByQuCode(data) {
    return service({
      url: '/common/account/getuserinfobyqrcode',
      method: 'get',
      params: data
    });
  },
  getUserQrcodeData() {
    return service({
      url: '/common/account/getuserqrcodedata',
      method: 'get'
    });
  },
  getUserMerchant() {
    return service({
      url: '/common/account/getUserMerchant',
      method: 'get'
    });
  },
  setPushMessageStatus(status) {
    return service({
      url: '/common/account/setPushMessageStatus',
      method: 'put',
      params: { status }
    });
  }
};

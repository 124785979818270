import service from '@/setup/request';
export default {
  orderMoneyTrend(params) {
    return service({
      url: 'statistics/supplier/ordermoneytrend',
      method: 'get',
      params: params
    });
  },
  orderGoodsCategoryTrend(params) {
    return service({
      url: 'statistics/supplier/ordergoodscategorytrend',
      method: 'get',
      params: params
    });
  },
  orderMoneySummary(params) {
    return service({
      url: 'statistics/supplier/orderMoneySummary',
      method: 'get',
      params
    });
  },

  getGoodsStatistics(data){
    return service({
        url:'/statistics/supplier/goods',
        method:'get',
        params:data
    })
  },
  getOrdermoneytrend(data){
    return service({
        url:'/statistics/supplier/ordermoneytrend',
        method:'get',
        params:data
    })
  },
  getOrderStatistics(data){
    return service({
        url:'/statistics/supplier/merchantorder',
        method:'get',
        params:data
    })
  },
  getOrdermoneysummary(data){
      return service({
          url:'/statistics/supplier/ordermoneysummary',
          method:'get',
          params:data
      })
  }
};

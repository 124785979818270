import service from '@/setup/request';
export default {
  // 获取代理商自己的商品
  getSupplierGoodsList() {
    return service({
      url: '/common/goods/mygoods',
      method: 'get'
    });
  },
  // 获取总公司商品列表
  getGoodsInfoList(data) {
    return service({
      url: '/goods/merchantgoods/goodsInfoList',
      method: 'get',
      params: data
    });
  },
  supplierCreateGoods(data) {
    return service({
      url: '/goods/merchantgoods/add',
      method: 'post',
      data
    });
  },
  supplierDeleteGoods(params) {
    return service({
      url: '/goods/merchantgoods/delete',
      method: 'delete',
      params
    });
  },
  supplierEditGoods(data) {
    return service({
      url: '/goods/merchantgoods/edit',
      method: 'put',
      data
    });
  },
  /**
   * 获取商品分类
   * @returns
   */
  getCategoryList() {
    return service({
      url: '/common/goods/category/list',
      method: 'get'
    });
  },
  /**
   * 获取商户所有代理商商品
   * @returns
   */
  getGoods(params) {
    return service({
      url: '/common/goods/all',
      method: 'get',
      params: params
    });
  },
  // 获取商户商品列表
  getMerchantGoodsList(data) {
    return service({
      url: '/goods/merchantgoods/list',
      method: 'get',
      params: data
    });
  },
  // 获取商户商品列表不分页
  getMerchantGoodsListNoPage(data) {
    return service({
      url: '/goods/merchantgoods/listnopage',
      method: 'get',
      params: data
    });
  },
  /**
   * 获取商品详情
   * @param {*} id
   * @returns
   */
  getGoodsDetail(id) {
    return service({
      url: '/common/goods/detail',
      method: 'get',
      params: { id }
    });
  },
  /**
   * 获取供应商商品
   * @param {*} supplierId
   * @returns
   */
  getSupplierGoods(supplierId) {
    return service({
      url: '/common/goods/suppliergoods',
      method: 'get',
      params: { supplierId }
    });
  },
  /**
   * 获取收藏商品
   * @returns
   */
  getFavoriteGoods() {
    return service({
      url: '/goods/goodsfavorite/list',
      method: 'get'
    });
  },
  /**
   * 添加收藏
   * @param {*} id
   * @returns
   */
  favoriteGoods(id) {
    return service({
      url: '/goods/goodsfavorite/favorite',
      method: 'post',
      data: { goodsId: id }
    });
  },
  /**
   * 取消收藏
   * @param {*} id
   * @returns
   */
  unFavoriteGoods(id) {
    return service({
      url: '/goods/goodsfavorite/unfavorite',
      method: 'post',
      data: { goodsId: id }
    });
  },
  /**
   * 获取促销供应商
   * @returns
   */
  getProotionSupplier() {
    return service({
      url: '/common/prootion/suppliers',
      method: 'get'
    });
  },
  /**
   * 获取全部促销商品
   * @returns
   */
  getProotionGoods() {
    return service({
      url: '/common/prootion/goods',
      method: 'get'
    });
  },
  /**
   * 根据id获取促销商品
   * @param {*} supplierId
   * @returns
   */
  getProotionGoodsBySupplier(supplierId) {
    return service({
      url: '/common/prootion/suppliergoods',
      method: 'get',
      params: { supplierId }
    });
  },
  getRecentbuyGoods() {
    return service({
      url: '/common/goods/recentbuy',
      method: 'get'
    });
  },
  publish(id) {
    return service({
      url: '/goods/merchantgoods/publish',
      method: 'put',
      data: { id }
    });
  },
  unpublish(id) {
    return service({
      url: '/goods/merchantgoods/unpublish',
      method: 'put',
      data: { id }
    });
  },
  editprice(data) {
    return service({
      url: '/goods/merchantgoods/editprice',
      method: 'put',
      data
    });
  }
};
// /common/prootion/suppliers

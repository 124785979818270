import axios from 'axios';
import { BASE_API_URL, VERSION } from '@/config/env';
import { get } from 'lodash-es';
import router from '../router';
import { getToken } from '@/utils';
import { Toast } from 'vant';
import rbApp from '@/utils/rbApp';

const service = axios.create({
  baseURL: BASE_API_URL,
  timeout: 30000
});

service.interceptors.request.use(
  async config => {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.common['App-ClientId'] = `mobile`;
    config.headers.common['App-Version'] = VERSION;
    return config;
  },
  err => {
    throw new Error(err);
  }
);

service.interceptors.response.use(
  response => {
    const code = get(response, 'data.code');
    if (typeof code === 'number' && code !== 0) {
      if (code === 1400) {
        rbApp.logout();
      } else {
        Toast.fail(response.data.message);
      }
      return Promise.reject(response.data);
    }
    return response.data;
  },
  error => {
    const statusCode = get(error, 'response.status');
    if (statusCode) {
      if (statusCode === 401) {
        rbApp.logout();
      }
    }
    return Promise.reject(error);
  }
);
export default service;

export default [
  {
    path: '/test/a',
    component: () => import('../views/test/A.vue')
  },
  {
    path: '/test/b',
    component: () => import('../views/test/B.vue')
  },
  {
    path: '/test/c',
    component: () => import('../views/test/C.vue')
  },
  {
    path: '/test/d',
    component: () => import('../views/test/D.vue')
  }
];

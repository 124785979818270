import { createStore } from 'vuex';
import goods from './modules/goods';
import app from './modules/app';
import mainPage from './modules/mainPage';
import getters from './getters';
import merchant from './modules/merchant';
import agency from './modules/agency';
import order from './modules/order'

// 经销商
import supplierGoods from './modules/supplier/goods'
export default createStore({
  modules: {
    goods,
    app,
    mainPage,
    merchant,
    agency,
    order,
    supplierGoods
  },
  getters
});

export default [
  // 商户首页
  {
    path: '/merchant/main',
    name: 'merchantMain',
    component: () => import('../views/merchants/main/Main')
  },
  // 代理商商品列表
  {
    path: '/merchant/supplier/goods',
    name: 'merchantGoods',
    component: () => import('../views/merchants/goods/SupplierGoods')
  },
  // 商品详情
  {
    path: '/merchant/goods/detail',
    name: 'merchantGoodsDetail',
    component: () => import('../views/merchants/goods/GoodsDetail')
  },
  // 常购清单
  {
    path: '/merchant/goods/history',
    name: 'merchantGoodsHistory',
    component: () => import('../views/merchants/goods/GoodsHistory')
  },
  // 促销代理商
  {
    path: '/merchant/promotion/suppliers',
    name: 'merchantPromotionSuppliers',
    component: () => import('../views/merchants/promotion/SupplierList')
  },
  // 促销商品
  {
    path: '/merchant/promotion/goods',
    name: 'merchantPromotionGoods',
    component: () => import('../views/merchants/promotion/GoodsList')
  },
  // 商品搜索
  {
    path: '/merchant/goods/search',
    name: 'merchantGoodsSearch',
    component: () => import('../views/merchants/goods/GoodsSearch')
  },
  // 购物车
  {
    path: '/merchant/shoppingcard',
    name: 'merchantShoppingCardPage',
    component: () => import('../views/merchants/order/ShoppingCardPage')
  }
];

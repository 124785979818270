import api from '@/api';
import { get } from 'lodash-es';
export default {
  state: {
    // 经销商自己的商品, 用于代下单
    // 没有购物车, 直接使用num字段
    supplierGoods: []
  },
  mutations: {
    setSupplierGoods(state, data) {
      state.supplierGoods = data;
    }
  },
  actions: {
    getSupplierGoods({ commit, state }) {
      commit('setSupplierGoods', []);
      return new Promise(resolve => {
        api.goods.getSupplierGoodsList().then(response => {
          let goods = response.data.map(m => {
            return { ...m, num: 0 };
          });
          commit('setSupplierGoods', goods || []);
          resolve(goods);
        });
      });
    }
  }
};

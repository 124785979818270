import service from '@/setup/request';
export default {
  /**
   * 获取个人信息
   * @returns
   */
  getUserInfo() {
    return service({
      url: '/common/account/getuserinfo',
      method: 'get'
    });
  },
  /**
   * 更新个人信息
   * @returns
   */
  updateUserInfo(data) {
    return service({
      url: '/common/account/updateuserinfo',
      method: 'put',
      data
    });
  },
  /**
   * 修改登录密码
   * @param {*} data
   * @returns
   */
  changePassword(data) {
    return service({
      url: '/common/account/changepassword',
      method: 'post',
      data
    });
  },
  // 修改手机号码发送短信
  changePhoneSendSms(data) {
    return service({
      url: '/common/account/updateuserphone/sendsms',
      method: 'post',
      data: data
    });
  },
  // 修改手机号码
  changePhone(data) {
    return service({
      url: '/common/account/updateuserphone',
      method: 'put',
      data: data
    });
  },
  // 注销账号检查
  cancellationAccontCheck() {
    return service({
      url: '/common/account/cancellationAccontCheck',
      method: 'get'
    });
  },
  // 注销账号提交
  cancellationAccontSubmit() {
    return service({
      url: '/common/account/cancellationAccontSubmit',
      method: 'put'
    });
  },
  // 注销账号撤销
  cancellationAccontCancel() {
    return service({
      url: '/common/account/cancellationAccuntCancel',
      method: 'put'
    });
  },
  // 注销账号完成提交
  cancellationAccuntCamplete() {
    return service({
      url: '/common/account/cancellationAccuntCamplete',
      method: 'put'
    });
  }
};

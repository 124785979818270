import service from "@/setup/request";
export default {
    /**
     * 收货地址列表
     * @returns 
     */
    shippingaddress(){
        return service({
            url:'/merchant/shippingaddress/list',
            method:'get'
        })
    },
    getMerchantAddress(id){
        return service({
            url:'/common/merchant/shippingaddress',
            method:'get',
            params:{merchantId:id}
        })
    },
    /**
     * 创建地址
     * @param {*} data 
     * @returns 
     */
    createaddress(data){
        return service({
            url:'/merchant/shippingaddress/add',
            method:'post',
            data
        })
    },
    /**
     * 设置默认地址
     * @param {*} id 
     * @returns 
     */
    setDefault(id){
        return service({
            url:'/merchant/shippingaddress/setdefault',
            method:'put',
            data:{id}
        })
    },
    /**
     * 删除地址
     * @param {*} id 
     * @returns 
     */
    deleaddress(id){
        return service({
            url:'/merchant/shippingaddress/delete?ids='+id,
            method:'delete'
        })
    },
    /**
     * 编辑地址
     * @param {*} data 
     * @returns 
     */
    editaddress(data){
        return service({
            url:'/merchant/shippingaddress/edit',
            method:'put',
            data
        })
    },
    /**
     * 获取地址详情
     * @param {*} id 
     * @returns 
     */
    getaddressdetail(id){
        return service({
            url:'/merchant/shippingaddress/detail?id='+id,
            method:'get'
        })
    }
}